/* eslint-disable */
export const filterList = [
  { type: 'all', name: 'All' },
  { type: 'gong', name: '工业设计' },
  { type: 'she', name: '视觉设计' },
  { type: 'bao', name: '包装设计' },
  { type: 'app', name: 'APP/Web' }
]

export const bannerList = [
  { type: 1, name: '非轮播' },
  { type: 2, name: '首页轮播' },
  { type: 3, name: '其他轮播' },
]

// export const uploadHost = 'http://localhost:3001'
// export const uploadHost = 'http://82.157.176.148:3001'
export const uploadHost = '//www.bsds100.com'
// export const uploadHost = 'http://175.27.189.125'
// export const uploadHost = ''
