<template>
  <div id="app">
    <el-scrollbar view-class="app-scrollbar">
      <common>
        <router-view/>
      </common>
    </el-scrollbar>
  </div>
</template>

<script>
const Common = () => import(/* webpackChunkName: "Common" */ '@/components/Common')

export default {
  name: "App",
  components: {
    Common
  }
};
</script>

<style>
#app {
  font-family: Noto Sans SC;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
