import Vue from 'vue'
import Router from 'vue-router'

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({
  showSpinner: false
 });

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home')
const About = () => import(/* webpackChunkName: "About" */ '@/views/About')
const Detail = () => import(/* webpackChunkName: "Detail" */ '@/views/Detail')
const Cont = () => import(/* webpackChunkName: "Cont" */ '@/views/Cont')
const Jobs = () => import(/* webpackChunkName: "Jobs" */ '@/views/Jobs')
 
Vue.use(Router)

export const constantRouter = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: '公司介绍'
    }
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: Jobs,
    meta: {
      title: '公司介绍'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail,
    meta: {
      title: '项目详情'
    }
  },
  {
    path: '/cont',
    name: 'cont',
    component: Cont,
    meta: {
      title: '联系我们'
    }
  },
]

const router = new Router({
  routes: constantRouter.concat([{
    path: '/',
    redirect: '/home'
  }]),
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  next()
})

router.afterEach(() => {
  NProgress.done();
 });

export default router
